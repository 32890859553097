import React, { useEffect } from "react";
import { useStaticQuery, graphql, PageProps, navigate } from "gatsby";
import { v4 as uuidv4 } from "uuid";
import Layout from "../components/layout";
import SearchBox from "../components/contentElements/searchBox";
import H1 from "../components/htmlElements/h1";

import { informationPages } from "../data/menuItems";
import { parseLocation } from "../lib/functions";
import getApiData from "../lib/getApiData";

export default function IndexRoute(props: PageProps) {
  const params = parseLocation(props);

  /**
   * Redirect legacy details pages to the new ones
   */
  useEffect(() => {
    (async () => {
      if (params && params.id !== "") {
        try {
          const response = await getApiData({
            endpoint: `lookup/${params.id.replace("%3a", ":")}`,
            method: "get",
          });

          if (response?.status === 200) {
            navigate(`/details/${response.data}`);
          }
        } catch (error) {
          // Send error message to slack?
          console.log("🚀 ~ error", error);
        }
      }
    })();
  });

  const query = useStaticQuery(graphql`
    query {
      homepageContentQuery: allMarkdownRemark(
        filter: { frontmatter: { title: { glob: "Welcome to IRIS" } } }
      ) {
        edges {
          node {
            frontmatter {
              title
            }
            html
          }
        }
      }
      homepageSidebarContentQuery: allMarkdownRemark(
        filter: { frontmatter: { title: { glob: "Homepage Sidebar Content" } } }
      ) {
        edges {
          node {
            frontmatter {
              title
            }
            html
          }
        }
      }
    }
  `);

  const homepageContent = query.homepageContentQuery.edges[0].node.html;
  const sidebarContent = query.homepageSidebarContentQuery.edges[0].node.html;

  // Create information menu
  const informationMenu = informationPages
    .map((item: any) => {
      return `<li key=${uuidv4()}>
          <a href=${item.link}>${item.label}</a>
        </li>
      `;
    })
    .join("")
    .trim();

  const wrappedInformationMenu = `<ul className="list-disc list-inside">${informationMenu}</ul>`;

  // Change the H2 on the homepage
  const homepageContentModified = homepageContent
    .replace(/<h2>/g, `<h2 class="text-2xl font-bold font-heading mt-0 pb-5">`)
    .replace("[LINKS]", wrappedInformationMenu)
    .trim();

  // Change the H2 on the homepage
  const sidebarContentModified = sidebarContent
    .replace(/<h2>/g, `<h2 class="text-2xl font-bold font-heading mt-0 pb-5">`)
    .trim();

  return (
    <Layout>
      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-12 md:col-span-7">
          <H1 innerContent="Welcome to IRIS" additionalClasses="pb-5" />
          <div
            className="markdownText"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: homepageContentModified,
            }}
          />
          {/* {homepageContentModified} */}
          {/* <ul className="list-disc list-inside">{informationMenu}</ul> */}
        </div>

        <div className="col-span-12 md:col-span-5 mb-5">
          <SearchBox parent="homepage" />

          <div className="bg-white rounded-lg shadow mx-auto sm:overflow-hidden  top-5 mt-5">
            <div className="p-5">
              <div className="block pb-5">
                Submission and upload of new material
              </div>
              <a
                href="/submit/new/"
                className="bg-blue hover:bg-blue-hover text-white no-underline py-2 px-4 rounded text-center shadow-md mt-5"
              >
                Submit
              </a>
            </div>
          </div>
        </div>

        <div className="col-span-12 md:col-span-7">
          <div
            className="markdownText"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: sidebarContentModified,
            }}
          />
        </div>

        <div className="col-span-12 md:col-span-5">
          <div className="grid grid-cols-12 gap-4">
            <div className="col-span-12">
              <div className="place-items-center">
                <a
                  href="https://oasis-database.org/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src="/images/oasis-logo.png"
                    alt="Oasis"
                    className="scale-75 md:scale-50 lg:scale-50"
                  />
                </a>
              </div>
            </div>
          </div>

          <div className="col-span-12 border-t border-gray-2 mt-5 pt-5 text-center place-items-center">
            Registered on
            <br />
            <img
              style={{
                height: `50px`,
                display: `inline-block`,
                marginRight: 10,
              }}
              alt="re3data Logo"
              src="/images/re3data.svg"
            />
            <img
              style={{
                height: `50px`,
                display: `inline-block`,
                marginRight: 10,
              }}
              alt="Fairsharing Logo"
              src="/images/fairsharing-logo.svg"
            />
          </div>

          <div className="col-span-12 border-t border-gray-2 mt-5 pt-5 text-center place-items-center">
            <img
              style={{
                height: `50px`,
                display: `inline-block`,
                marginRight: 10,
              }}
              alt="ESRC Logo"
              src="/images/ESRC-LOGO.gif"
            />
            <img
              style={{
                height: `50px`,
                display: `inline-block`,
                marginRight: 10,
              }}
              alt="British Academy Logo"
              src="/images/british-academy-logo.jpg"
            />
            <img
              style={{ height: `50px`, display: `inline-block` }}
              alt="Wiley Logo"
              src="https://www.wiley.com/medias/Wiley-Logo-White.png?context=bWFzdGVyfGltYWdlc3w3NDUzfGltYWdlL3BuZ3xzeXMtbWFzdGVyL2ltYWdlcy9oMTYvaDUwLzg4ODM2Nzk3MjM1NTAvV2lsZXkgTG9nbyBXaGl0ZS5wbmd8Y2IxYmY3M2IyNzE0NTk3NzM1ZjEzYTk5YTQ5YjUzNzE0ZTMzYTcxYmViMjBmMGUwNGU4YjEwZTYwMTYwOGU0Zg"
            />
            <div style={{ padding: `10px` }}>
              <img
                style={{
                  height: `50px`,
                  display: `inline-block`,
                  marginRight: 10,
                }}
                alt="John Benjamins Logo"
                src="/images/JB_logo.jpg"
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
